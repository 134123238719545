import api from '@libs/api'

import { handleServiceError } from '@utils/errors'
import type { UserType } from '@utils/types'

interface IBuyerSignUp {
  id?: string
  firstName: string
  lastName: string
  companyName: string
  email: string
  password: string
  legacy?: boolean
}

interface IVendorSignUp {
  id?: string
  firstName: string
  lastName: string
  companyName: string
  email: string
  password: string
  legacy?: boolean
  promotionCodeId?: string
  offerId?: string
  partnerId?: string
  transactionId?: string
}

interface IConfirmUser {
  firstName?: string
  lastName?: string
  email: string
  code: string
  userType: UserType
}

interface IActivateUser {
  userType: UserType
}

interface ISendVerifyCodeEmail {
  userType: UserType
}

interface IResendVerificationCodeToEmail {
  email: string
  userType: UserType
}
interface IForgotPassword {
  email: string
  userType: UserType
}

interface IChangePassword {
  currentPassword: string
  newPassword: string
  userType: UserType
}
interface IResetPassword {
  email: string
  newPassword: string
  code: string
  userType: UserType
}

const resendVerificationCodeToEmail = async ({
  email,
  userType,
}: IResendVerificationCodeToEmail) => {
  try {
    const response = await api.post({
      path: `/${userType}/resend-verification-code`,
      authenticate: false,
      data: {
        email,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const forgotPassword = async ({ email, userType }: IForgotPassword) => {
  try {
    const response = await api.post({
      path: `/${userType}/forgot-password`,
      authenticate: false,
      data: {
        email,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const changePassword = async ({
  currentPassword,
  newPassword,
  userType,
}: IChangePassword) => {
  try {
    const response = await api.post({
      path: `/${userType}/change-password`,
      data: {
        currentPassword,
        newPassword,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const resetPassword = async ({
  email,
  newPassword,
  code,
  userType,
}: IResetPassword) => {
  try {
    const response = await api.post({
      path: `/${userType}/reset-password`,
      authenticate: false,
      data: {
        email,
        newPassword,
        code,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const buyerSignUp = async ({
  id,
  firstName,
  lastName,
  companyName,
  email,
  password,
  legacy,
}: IBuyerSignUp) => {
  try {
    const response = await api.post({
      path: '/buyers/signup',
      authenticate: false,
      data: {
        id,
        firstName,
        lastName,
        companyName,
        email,
        password,
        legacy,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const vendorSignUp = async ({
  id,
  firstName,
  lastName,
  companyName,
  email,
  password,
  legacy,
  promotionCodeId,
  offerId,
  partnerId,
  transactionId,
}: IVendorSignUp) => {
  try {
    const response = await api.post({
      path: '/vendors/signup',
      authenticate: false,
      data: {
        id,
        firstName,
        lastName,
        companyName,
        email,
        password,
        legacy,
        promotionCodeId,
        offerId,
        partnerId,
        transactionId,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const confirmUser = async ({
  firstName,
  lastName,
  email,
  code,
  userType,
}: IConfirmUser) => {
  try {
    const response = await api.post({
      path: `/${userType}/confirm-user`,
      authenticate: false,
      data: {
        firstName,
        lastName,
        email,
        code,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const activateUser = async ({ userType }: IActivateUser) => {
  try {
    const response = await api.post({
      path: `/${userType}/activate`,
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

const sendVerifyCodeEmail = async ({ userType }: ISendVerifyCodeEmail) => {
  try {
    const response = await api.post({
      path: `/${userType}/verify-email`,
      data: {
        userType,
      },
    })

    return response
  } catch (err: any) {
    throw handleServiceError(err)
  }
}

export default {
  buyerSignUp,
  vendorSignUp,
  confirmUser,
  sendVerifyCodeEmail,
  resendVerificationCodeToEmail,
  forgotPassword,
  changePassword,
  resetPassword,
  activateUser,
}
