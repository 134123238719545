import { ReactNode } from 'react'

import { AlertColor } from '@mui/material'

import { IEntityContentJson } from '@mailupinc/bee-plugin/dist/types/bee'
import { Channel } from 'stream-chat'
import {
  Attachment,
  ChannelData,
  ChannelResponse,
  DefaultGenerics,
  Event,
  LiteralStringForUnion,
  MessageResponse,
  UR,
  UserResponse,
} from 'stream-chat/dist/types/types'

import { StandardPlanPeriod } from '@utils/plan-detail'
import { ResourceType } from '@utils/types/resources'

export type ValueOf<T> = T[keyof T]

export type WithPrefix<T extends string> = `${T}${string}`

export interface IAlert {
  type: AlertColor
  message: string
  action?: ReactNode
}

export type ChatbotConversation = {
  id: string
  name: string
  createdDate: Date
}

export enum ChatbotUser {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export enum UserType {
  ADMIN = 'admins',
  VENDOR = 'vendors',
  BUYER = 'buyers',
}

export enum MetricType {
  UP = 'up',
  DOWN = 'down',
  EQUAL = 'equal',
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITED = 'invited',
  DELETED = 'deleted',
  PENDING = 'pending',
  SUSPENDED = 'suspended',
}

export enum TeamMemberStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITED = 'invited',
  DELETED = 'deleted',
}

export enum PaymentPlanType {
  STANDARD = 'standard',
  SUPPORT_HOURS = 'support_hours',
  ENTERPRISE = 'enterprise',
}

export enum UserStatusOption {
  NONE = 'none',
  DELETED_BY_ADMIN = 'admin-delete',
  DELETED_BY_USER = 'user-delete',
}

export enum UserBatchAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
  RECOVER = 'recover',
}

export enum TeamMemberBatchAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
  RECOVER = 'recover',
}

export enum MainCategoryBatchAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
}

export enum SubCategoryBatchAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
}

export enum VerticalExpertiseBatchAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
}

export enum BrandBatchAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
}

export enum BeeAction {
  DELETE = 'delete',
}

export enum GeoBatchAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  DELETE = 'delete',
}

export enum ProductServiceBatchAction {
  PUBLISH = 'publish',
  TURN_INTO_DRAFT = 'turn into draft',
  UNARCHIVE = 'unarchive',
  ARCHIVE = 'archive',
  DELETE = 'delete',
}

export enum SolutionBatchAction {
  PUBLISH = 'publish',
  UNARCHIVE = 'unarchive',
  UNPUBLISH = 'unpublish',
  ARCHIVE = 'archive',
  DELETE = 'delete',
}

export enum AdminRole {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  SUPPORT = 'support',
  FINANCE = 'finance',
  SALES = 'sales',
}

export enum AdminAction {
  READ = 'read',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  CREATE_AND_EDIT_TEAM_MEMBERS = 'create-and-edit-team-members',
  MANAGE_PRESS_OR_BLOG_POSTS = 'manage-press-or-blog-posts',
  MANAGE_PUBLIC_WHITEPAPERS = 'manage-public-whitepapers',
  IMPERSONATE = 'impersonate',
  MANAGE_MATCHES = 'manage-matches',
  MANAGE_PLANS_SETTINGS = 'manage-plans-settings',
  ARCHIVE_ALL_ASSISTANCE_CHATS = 'archive-all-assistance-chats',
  MANAGE_SUPPORT_HOURS = 'manage-support-hours',
  IMPORT_SOLUTIONS = 'import-solutions',
  IMPORT_OPPORTUNITIES = 'import-opportunities',
  MANAGE_COUPONS = 'manage-coupons',
  MANAGE_ENTERPRISE_PLANS = 'manage-enterprise-plans',
}

export enum ProductServiceType {
  PRODUCT = 'product',
  SERVICE = 'service',
}

export enum SolutionType {
  PRODUCT = 'product',
  SERVICE = 'service',
}

export enum ProductServiceStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export enum WhitePaperStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum VideoStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum SolutionStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export enum GeoFocus {
  NATIONAL = 'NATIONAL',
  REGIONAL = 'REGIONAL',
}

export enum GeoFocus_v2 {
  NATIONAL = 'national',
  REGIONAL = 'regional',
}

export enum ChatStatus {
  ONLINE = 'online',
  IDLE = 'idle',
  OFFLINE = 'offline',
}

export interface IVendor {
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  status: UserStatus
  statusOption?: string
  officialTitle?: string
  personalEmail?: string
  phone?: string
  company?: ICompany
  subscription?: ISubscriptionPlan
  coupon?: ICoupon
  promotionCode?: IPromotionCode
  referral?: IReferral
  avatar?: string
  chatStatus?: boolean
  currentChatStatus?: ChatStatus
  chatStatusOfflineAutomaticResponse?: string
  chatToken?: string
  legacy?: boolean
  legacySignUpURL?: string
  externalId?: string
  timeZone?: string
  additionalEmails?: string[]
  authCalendars?: IAuthCalendar[]
  subscriptions?: IPaymentSubscription[]
  paymentActive: boolean
  hasSeenSolutionV2Onboarding?: boolean
  updatedDate?: Date
  createdDate?: Date
  deletedDate?: Date
  activatedDate?: Date
}

export interface IBuyer {
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  referral?: string
  officialTitle?: string
  personalEmail?: string
  phone?: string
  company?: ICompany
  status: UserStatus
  statusOption?: string
  opportunities?: IOpportunity[]
  avatar?: string
  currentChatStatus?: ChatStatus
  chatToken?: string
  isNewLead?: boolean
  isInactiveLead?: boolean
  isLeadDisabled?: boolean
  legacy?: boolean
  legacySignUpURL?: string
  chatbotDetails?: IBuyerChatbotDetails
  externalId?: string
  timeZone?: string
  createdDate?: Date
  updatedDate?: Date
  deletedDate?: Date
  activatedDate?: Date
}

export interface IBuyerChatbotDetails {
  interacted: boolean
}
export interface IUserChangelog {
  id: string
  action: ChangelogAction
  admin: IAdmin
  user: IBuyer | IVendor
  updatedDate: Date
  createdDate: Date
}

export interface ITeamMemberChangelog {
  id: string
  action: ChangelogAction
  user: IAdmin
  performedByAdmin: IAdmin
  updatedDate: Date
  createdDate: Date
}

export interface IProductServiceChangelog {
  id: string
  action: ProductServiceChangelogAction
  admin?: IAdmin
  vendor?: IVendor
  productService: IProductService
  updatedDate: Date
  createdDate: Date
}

export interface ISolutionChangelog {
  id: string
  action: SolutionChangelogAction
  admin?: IAdmin
  vendor?: IVendor
  solution: ISolution
  updatedDate: Date
  createdDate: Date
}

export interface IAdmin {
  id: string
  userId: string
  firstName?: string
  lastName?: string
  email: string
  avatar?: string
  chatStatus?: boolean
  currentChatStatus?: ChatStatus
  chatStatusOfflineAutomaticResponse?: string
  officialTitle?: string
  role: AdminRole
  status: UserStatus
  chatToken?: string
  createdDate?: Date
  updatedDate?: Date
}

export interface IImpersonatedUser {
  userId: string
  returnUrl: string
  username: string
  userType: UserType
}

export interface ICompanyAddress {
  address1?: string
  address2?: string
  city?: string
  state?: string
  zipCode?: string
  phone?: string
  phoneExtension?: string
  financeContactFirstName?: string
  financeContactLastName?: string
  financeContactEmail?: string
}

export interface ICompany extends ICompanyAddress {
  id: string
  name: string
  description?: string
  descriptionDraft?: string | null
  descriptionTemplate?: IEntityContentJson
  descriptionTemplateDraft?: IEntityContentJson | null
  bannerDescription?: string
  geoFocus?: string
  banner?: string
  logo?: string
  revenueRange?: IRevenueRange | null
  mainCategories?: IMainCategory[]
  brands?: IBrand[]
  subCategories?: ISubCategory[]
  verticalExpertises?: IVerticalExpertise[]
  geos?: IGeo[]
  distributors?: IDistributor[]
  markets?: IMarket[]
  vendor?: IVendor
  whitepapers?: IWhitePaper[]
  videos?: IVideo[]
  favoritedByBuyers?: IFavoritedByBuyers[]
  isBuyerFavorited?: boolean
}

export interface ICompanyV2
  extends Omit<ICompany, 'mainCategories' | 'brands' | 'subCategories'> {
  categories: ICategory_v2[]
}

export interface Credentials {
  email: string
  password: string
}
export interface IApiData<T, K = void> {
  count: number
  data: T
  extras?: K
}

export interface IFilter {
  field: string
  value: any
}

export interface IQueryFilters {
  page: number
  limit: number
  negativeOffset?: number
  filters?: IFilter[]
  sortModel?: ISortModel
  nextPageToken?: string
}

export interface IQueryFiltersStripe {
  nextPageToken?: string
  limit: number
  filters?: IFilter[]
}

export interface ISortModel {
  field: string
  sort: string | null | undefined
}

export interface IMainCategory {
  id: string
  name: string
  color: string
  active: boolean
  createdDate?: Date
  updatedDate?: Date
}

export interface ISubCategory {
  id: string
  name: string
  color: string
  mainCategories: IMainCategory[]
  active: boolean
  createdDate?: Date
  updatedDate?: Date
}

export interface ICategory_v2 {
  id: string
  name: string
  description: string
  active: boolean
  categoryFilter: ICategoryFilter_v2
  createdDate?: Date
  updatedDate?: Date
}

export interface ICategoryFilter_v2 {
  id: string
  name: string
  active: boolean
  createdDate?: Date
  updatedDate?: Date
}

export interface ICategoryFeature_v2 {
  id: string
  name: string
  description: string
  active: boolean
  category: ICategory_v2
  createdDate?: Date
  updatedDate?: Date
}

export interface IGeo {
  id: string
  name: string
  color: string
  active: boolean
  createdDate?: Date
  updatedDate?: Date
}
export interface IVerticalExpertise {
  id: string
  name: string
  active: boolean
  color: string
  createdDate?: Date
  updatedDate?: Date
}

export interface IDistributor {
  id: string
  name: string
  active: boolean
  otherDistributorName?: string
  createdDate?: Date
  updatedDate?: Date
}

export interface IMarket {
  id: string
  name: string
  active: boolean
  createdDate?: Date
  updatedDate?: Date
}
export interface IRevenueRange {
  id: string
  name: string
  active: boolean
  createdDate?: Date
  updatedDate?: Date
}
export interface IBrand {
  id: string
  name: string
  description: string
  logo: string | null | undefined
  banner: string | null | undefined
  color: string
  productsServices?: IProductService[]
  companies?: ICompany[]
  active: boolean
  favorite: boolean
  createdDate?: Date
  updatedDate?: Date
}

export interface IWhitePaper {
  id: string
  cover?: string
  coverFileName?: string
  title: string
  category?: IMainCategory | null
  company?: string
  summary: string
  paper?: string | null | undefined
  paperFileName?: string
  url?: string
  status?: WhitePaperStatus
  createdDate?: Date
  updatedDate?: Date
}

export interface IVideo {
  id: string
  cover?: string
  coverFileName?: string
  title: string
  category?: IMainCategory | null
  company?: ICompany
  summary: string
  video?: string | null | undefined
  videoFileName?: string
  url?: string
  status?: VideoStatus
  createdDate?: Date
  updatedDate?: Date
}

export interface IFavoritedByBuyers {
  id: string
  userId: string
  companyId: string
}

export interface IProductService {
  id: string
  type?: ProductServiceType
  status: ProductServiceStatus
  name: string
  description?: string
  descriptionDraft?: string | null
  descriptionTemplate?: IEntityContentJson
  descriptionTemplateDraft?: IEntityContentJson | null
  descriptionImages?: string[]
  bannerDescription?: string
  mainCategories?: IMainCategory[]
  subCategories?: ISubCategory[]
  companies?: ICompany[]
  brand?: IBrand
  banner?: string
  logo?: string
  isPublic: boolean
  createdByAdmin?: IAdmin
  createdByVendor?: IVendor
  createdDate?: Date
  updatedDate?: Date
  autoMonitoring?: boolean
  sourceUrls?: string[]
  solutionIds?: string[]
}

export interface IProductServiceRights {
  isOwner?: boolean
  isAdmin?: boolean
  isVendorAllowed?: boolean
}

export interface ISolution {
  id: string
  status: SolutionStatus
  name: string
  slug?: string
  description?: string
  descriptionDraft?: string | null
  descriptionTemplate?: IEntityContentJson
  descriptionTemplateDraft?: IEntityContentJson | null
  descriptionImages?: string[]
  bannerDescription?: string
  mainCategories?: IMainCategory[]
  subCategories?: ISubCategory[]
  logo?: string
  banner?: string
  initialLeadBudget?: number
  finalLeadBudget?: number
  initialPricingRange?: number
  finalPricingRange?: number
  geoFocus?: string
  geos?: IGeo[]
  solutionPaymentTypes?: ISolutionPaymentType[] | null
  productsServices?: IProductService[]
  opportunities?: IOpportunity[]
  createdByVendor?: IVendor
  isMatchAccepted?: boolean
  isFavorite?: boolean
  hasLegacyMatch?: boolean
  isIncomplete?: boolean
  legacy?: boolean
  createdDate?: Date
  updatedDate?: Date
}

export interface ISolutionImport extends Pick<ISolution, 'name' | 'legacy'> {
  vendorEmail: string
  externalId: string
}

export interface ISolutionPermissions {
  isAdmin?: boolean
  isOwner?: boolean
  isVendorAllowed?: boolean
}

export interface ISolutionMatch extends ISolution {
  brands: IBrand[]
  company: ICompany
}

export interface ISolutionWithCompanyV2 extends ISolution_v2 {
  company: ICompany
}

export interface IOpportunityImport {
  buyerEmail?: string
  buyerExternalId?: string
  solutionName?: string
  solutionExternalId?: string
  date: Date
}

export interface IBuyerSolutionSavedSearch {
  id: string
  name: string
  matchesCount: number
  filters?: Record<string, unknown>
  createdDate?: Date
  updatedDate?: Date
}

export interface IPaymentType {
  id: string
  name: string
  recurrenceInMonths?: number[]
  createdDate?: Date
  updatedDate?: Date
}

export interface ISolutionPaymentType {
  recurrenceInMonths?: number[]
  paymentType: IPaymentType
}

export interface IOpportunity {
  id: string
  buyer: IBuyer
  solution: ISolution
  solutionV2?: ISolution_v2
  opportunityRole?: IOpportunityRole
  purchaseTimeframe?: IOpportunityTimeframe
  evaluationTimeframe?: IOpportunityTimeframe
  status: OpportunityStatus
  chatChannelId?: string
  hidden?: boolean
  legacy?: boolean
  createdDate?: Date
  updatedDate?: Date
  matchedOn: Date
  unmatchedOn?: Date
}

export interface IOpportunityRole {
  id: string
  name: string
  createdDate?: Date
  updatedDate?: Date
}

export interface IOpportunityTimeframe {
  id: string
  name: string
  createdDate?: Date
  updatedDate?: Date
}

export interface AssistanceCustomFields extends ChannelData<DefaultGenerics> {
  advisorSupport: boolean
  assistedUserType: ChatUserType
  metadataUsername: string
  metadataCompanyName: string
  isReadyForSupport: boolean
  isAdminAssigned: boolean
  isArchived: boolean
  sourcePage: AssistanceChatSourcePage
  assignedAdminUserId?: string
  assignedAdmin?: IAdminAdvisorChatChannelUser
}

export type AdvisorChannel = Channel<DefaultGenerics> & {
  data: AssistanceChannelData
}

export type AssistanceChannelData =
  | (AssistanceCustomFields & ChannelResponse<DefaultGenerics>)
  | undefined

export type IChatAttachments = Attachment<{
  attachmentType: UR
  channelType: UR
  commandType: LiteralStringForUnion
  eventType: UR
  // eslint-disable-next-line @typescript-eslint/ban-types
  messageType: {}
  reactionType: UR
  userType: UR
}>[]

export interface IStreamChatCustomSendEventResponse {
  created_at: string
  received_at: Date
  text: string
  type: string
  user: UserResponse<DefaultGenerics>
}

export type IVendorChatMember = Pick<
  IVendor,
  | 'avatar'
  | 'userId'
  | 'firstName'
  | 'lastName'
  | 'officialTitle'
  | 'status'
  | 'chatStatus'
  | 'chatStatusOfflineAutomaticResponse'
> & { company: Pick<ICompany, 'name'> }

export enum AssistanceChatSourcePage {
  PROMOTIONAL_LANDING_PAGE = 'promotional-landing-page',
  BUYER_APP = 'buyer-app',
  VENDOR_APP = 'vendor-app',
}

export interface IChatData {
  chatChannelId: string
  companyChatChannelId: string
  opportunityChatChannels: IOpportunity[]
  isArchived?: boolean
}

export interface IVendorLeadChatData extends IChatData {
  lead: IBuyer
}

export interface IBuyerCompanyChatData extends IChatData {
  vendorChatMembers: IVendorChatMember[]
}

export enum AssistanceAutomaticMessageType {
  WELCOME = 'welcome',
  WAIT_FOR_SUPPORT = 'wait-for-support',
}

export interface IVisitorChatInfo {
  id?: string
  visitorName: string
  companyName?: string
  token?: string
}

export interface IChatUserExtra {
  company: { name: string }
}

export interface IChatMessage extends MessageResponse<DefaultGenerics> {
  extra?: IChatMessageExtra
}

export interface IChatMessageExtra {
  sentFromTlg?: boolean
  automaticResponse?: boolean
  automaticOfflineResponse?: boolean
  automaticWaitForSupportResponse?: boolean
  impersonatedBy: {
    id: string
    name: string
  }
}

export interface IAdminAdvisorChatChannelUser {
  userId: string
  firstName?: string
  lastName?: string
  email: string
}

export interface IAdminAdvisorStreamChatChannelData {
  assignedAdminUserId?: string
}

export enum StreamChatChannelType {
  MESSAGING = 'messaging',
  ASSISTANCE = 'assistance',
}

export enum ChatUserRole {
  ADMIN = 'admin',
  BUYER = 'buyer',
  VENDOR = 'vendor',
  GUEST = 'guest',
  OWNER = 'owner',
}

export enum ChatUserType {
  VISITOR = 'visitors',
  ADMIN = 'admins',
  VENDOR = 'vendors',
  BUYER = 'buyers',
}

export interface IChatUser {
  id: string
  avatar?: string
  name?: string
  email?: string
  companyName?: string
  officialTitle?: string
  token?: string
  notAvailable?: boolean
  type: ChatUserType
}

export interface IAdvisorChatData {
  channelId: string
  sourcePage: AssistanceChatSourcePage
  assistedUser: IChatUser
  assignedAdmin?: IChatUser
  unreadMessageCount: number
  latestMessage?: {
    userId: string
    text: string
    createdDate: Date
    attachments?: IChatAttachments
  }
}

export interface ILatestChatMessage {
  channelId: string
  belongsToUser: boolean
  createdOn: string
  readOn?: string
  name: string
  company: string
  reply: string
  hasAttachments?: boolean
}

interface IUserConversationData {
  companyChatChannelId: string
  isNew: boolean
  notAvailable: boolean
  messageStatus: string
  company: ICompany
  latestMessage?: ILatestChatMessage
}

export interface IBuyerConversationData extends IUserConversationData {
  vendor: Pick<IVendor, 'userId' | 'currentChatStatus'>
  solutionNames: string[]
}

export interface IBuyerConversationDataExtra {
  archivedChatsCount?: number
}

export interface IVendorConversationData extends IUserConversationData {
  buyer: IBuyer
}

export interface IVendorConversationDataExtra {
  archivedChatsCount?: number
}

export enum ChatMessageAssignStatusFilter {
  ALL = 'all',
  ASSIGNED = 'assigned',
  UNASSIGNED = 'unassigned',
}

export enum ChatMessageReadStatusFilter {
  UNREAD = 'unread',
  READ = 'read',
}

export enum ChatMessageStatus {
  FAILED = 'failed',
  SENDING = 'sending',
  RECEIVED = 'received',
  READ = 'read',
}

export interface IBuyerCompanyChatData {
  chatChannelId: string
  companyChatChannelId: string
  vendorChatMembers: IVendorChatMember[]
  opportunityChatChannels: IOpportunity[]
}

export interface IEntityCounter {
  newLeads: number
}

export interface IRequestDemoUrls {
  itCustomerRequestDemoUrl: string
  techVendorRequestDemoUrl: string
}

export interface ISolutionsV2Settings {
  setupOptionSuggestionUrl: string
  featureSuggestionUrl: string
  categorySuggestionUrl: string
}
export interface IEnterpriseSettings {
  financingFeeForTwoInstallments: number
  financingFeeForThreeInstallments: number
  gracePeriod: number
}

export interface ISolutionsV2Settings {
  setupOptionSuggestionUrl: string
  pricingOptionSuggestionUrl: string
}

export interface ISolutionCountResponse {
  totalCount: number
  publishedCount: number
  draftCount: number
  incompleteCount: number
  legacyCount: number
  nonLegacyCount: number
  archivedCount: number
  archivedLegacyCount: number
}

export interface ISolutionCountResponse_v2 {
  totalCount: number
  publishedCount: number
  draftCount: number
  incompleteCount: number
  legacyCount: number
  nonLegacyCount: number
  archivedCount: number
  archivedLegacyCount: number
  previouslyPublishedCount: number
}

export interface IMatchCountResponse {
  totalCount: number
  legacyCount: number
  vendorNotSubscribedCount: number
}

export interface ISolutionIncompleteCounter {
  count: number
}

export interface ICard {
  id: string
  name: string
  expMonth: number
  expYear: number
  country: string
  zipCode: string
  brand: string
  last4: string
  default: boolean
}

export enum PaymentPlanStatus {
  AVAILABLE = 'available',
  COMING_SOON = 'coming_soon',
  HIDDEN = 'hidden',
}

export interface IPaymentPlan {
  id: string
  stripeId?: string
  name: string
  alias: string
  type: PaymentPlanType
  highlighted: boolean
  status: PaymentPlanStatus
  settings?: IPlanSettings
  pricingPerPeriod: IPlanPricingPeriod
  pricings?: IPaymentPlanPricing[]
  coupons?: ICoupon[]
  subscriptions?: IPaymentSubscription[]
  parentPlan?: IPaymentPlan
  childrenPlans?: IPaymentPlan[]
  createdDate: Date
  updatedDate: Date
}

export enum BillingScheme {
  PER_UNIT = 'per_unit',
  TIERED = 'tiered',
}

export interface IPlanPricing {
  interval: PaymentInterval
  intervalCount: number
  amount: number
}

export interface IPlanPricePeriodData {
  id: string
  stripeId: string
  amount: number
  interval: PaymentInterval
  intervalCount: number
}

export interface IPlanPricingPeriod {
  [PlanPeriod.MONTHLY]: IPlanPricePeriodData
  [PlanPeriod.QUARTERLY]: IPlanPricePeriodData
  [PlanPeriod.HALF_YEARLY]: IPlanPricePeriodData
  [PlanPeriod.YEARLY]: IPlanPricePeriodData
  [PlanPeriod.BIENNIALLY]: IPlanPricePeriodData
}

export interface IPlanSettings {
  id: string
  maxSolutions: number
  includedSupportHours: number
  supportHoursRate: number
}

export interface IPaymentPlanPricing {
  id: string
  stripeId: string
  amount: number
  interval: PaymentInterval
  intervalCount: number
  customIntervalCount?: number
  current: boolean
}

export interface IPlan {
  id: string
  name: string
  alias: string
  pricingPerPeriod: IPlanPricingPeriod
  highlighted: boolean
  status: PaymentPlanStatus
  custom?: IPlanPricing
  settings?: IPlanSettings
  features?: IFeatureAvailability
  supportHourPlan?: IPaymentPlan
}

export interface ISubscriptionPlan {
  stripeId: string
  vendor: IVendor
  pricing: IPaymentPlanPricing
  plan: IPlan
  coupon?: ICoupon
  promotionCode?: IPromotionCode
  period: StandardPlanPeriod
  status: string
  remainingSolutions: number
  maxSolutions: number
  supportHours?: {
    stripeId: string
    includedHours: number
    rate: number
  }
  currentPeriodStart: string
  currentPeriodEnd: string
  nextUpcomingPaymentAttempt: string | null
  nextPaymentAttempt: string | null
  cancelAtPeriodEnd: boolean
  pendingInvoice: IInvoice | null
  collectionPausedUntil?: string
  enterpriseUser?: boolean
}

export interface IInvoice {
  id: string
  type: PaymentPlanType
  number: string
  amount: number
  installment?: number
  paymentMethod?: {
    card?: Pick<ICard, 'brand' | 'last4'>
    bank?: { name: string }
  }
  createdDate: string
  dueDate: string | null
  paidDate: string | null
  status: InvoiceStatus
  collectionMethod: 'charge_automatically' | 'send_invoice'
  hostedInvoiceUrl?: string
}

export interface IEnterpriseCampaign {
  id: string
  name: string
  numberOfLeads: number
  type: EnterpriseCampaignType
  subscription: IPaymentSubscription
  createdDate: Date
  updatedDate: Date
}

export enum EnterpriseCampaignType {
  MQL = 'mlq',
  MQL_PLUS = 'mlq_plus',
  BANT = 'bant',
}

export interface IEnterpriseCustomProduct {
  id: string
  name: string
  description: string
  subscription: IPaymentSubscription
  createdDate: Date
  updatedDate: Date
}

export interface IPaymentSubscription {
  id: string
  stripeId: string
  name?: string
  vendor: IVendor
  status: InternalSubscriptionStatus
  installments?: number
  totalAmount?: number
  feePercentage?: number
  gracePeriod?: number
  maxSolutions?: number
  includedSupportHours?: number
  supportHoursRate?: number
  period: PlanPeriod
  plan: IPaymentPlan
  parentSubscription?: IPaymentSubscription
  childrenSubscriptions?: IPaymentSubscription[]
  enterpriseCampaigns?: IEnterpriseCampaign[]
  enterpriseCustomProducts?: IEnterpriseCustomProduct[]
  pricing: IPaymentPlanPricing
  cancelDate?: Date
  expirationDate: Date
  createdDate: Date
  updatedDate: Date
}

export enum CouponDuration {
  ONCE = 'once',
  REPEATING = 'repeating',
  FOREVER = 'forever',
}

export interface ICoupon {
  id: string
  code: string
  duration: CouponDuration
  durationInMonths?: number
  amountOff?: number
  percentOff?: number
  maxRedemptions: number
  timesRedeemed: number
  valid: boolean
  vendor?: IVendor
  plan?: IPaymentPlan
  period?: PlanPeriod
  redeemBy?: Date
  createdDate?: Date
  updatedDate?: Date
}

export interface IPromotionCode {
  id: string
  stripeId: string
  couponId: string
  code: string
  name: string
  duration: CouponDuration
  durationInMonths?: number
  amountOff?: number
  percentOff?: number
  maxRedemptions?: number
  timesRedeemed: number
  firstTimeOrderOnly: boolean
  active: boolean
  valid: boolean
  expirationDate?: Date
  createdDate?: Date
  updatedDate?: Date
}

export interface ISupportHour {
  id: string
  hours: number
  rate?: number
  day: Date
  description: string
  status: SupportHourStatus
  subscription: IPaymentSubscription
  performedByAdmin: IAdmin
  createdByAdmin: IAdmin
  createdDate?: Date
  updatedDate?: Date
}

export interface IAdminArchivedChatCustomUserEvent {
  type: string
  source: string
  action: ArchivingAction
  results: {
    data: string[]
    count: number
    unreadChatCount: number
    unassignedChatCount: number
  }
}

export interface IAdvisorAssignedToChatCustomUserEvent {
  channelId: string
  assignedAdmin: {
    previous: string
    current: string
  }
  wasChatUnread: string
  markMessagesAsRead: string
}

export interface IMatchListingData {
  id: string
  status: OpportunityStatus
  buyer: Pick<IBuyer, 'firstName' | 'lastName' | 'email'> & {
    company: Pick<ICompany, 'name'>
  }
  solution?: Pick<ISolution, 'id' | 'name'> & {
    createdByVendor: Pick<IVendor, 'email'> & {
      company: Pick<ICompany, 'name'>
    }
  }
  matchedOn: Date | undefined
  unmatchedOn: Date | undefined
  opportunityRole: Pick<IOpportunityRole, 'name'>
  purchaseTimeframe: Pick<IOpportunityTimeframe, 'name'>
  evaluationTimeframe: Pick<IOpportunityTimeframe, 'name'>
  legacy: boolean
}
type CustomUserAttributeType<T extends string> = {
  [K in `custom:${T}`]: string
}

interface IUserAttribute extends CustomUserAttributeType<string> {
  email: string
  email_verified: string
  sub: string
}

interface ICognitoClient {
  endpoint: string
  fetchOptions: any
}

interface ICognitoTokenBase {
  jwtToken: string
  payload: any
}

export interface IAuthenticatedSignInResponse {
  Session: string | null
  attributes: IUserAttribute
  authenticationFlowType: string
  client: ICognitoClient
  keyPrefix: string
  pool: {
    client: ICognitoClient
    clientId: string
    storage: any
    userPoolId: string
    wrapRefreshSessionCallback: () => void
  }
  preferredMFA: string
  signInUserSession: {
    accessToken: ICognitoTokenBase
    idToken: ICognitoTokenBase
    refreshToken: {
      token: string
    }
    clockDrift: number
  } | null
  storage: any
  userDateKey: string
  username: string
}

export interface IChallengeSignInResponse
  extends Omit<IAuthenticatedSignInResponse, 'attributes'> {
  challengeName: string
  challengeParam: {
    requiredAttributes: string[]
    userAttributes: Omit<IUserAttribute, 'sub'>
  }
}

interface IBaseChatUserData {
  id: string
  name: string
  companyName?: string
  token: string
  idle?: boolean
  invisible?: boolean
}

export interface IVisitorChatUserData extends IBaseChatUserData {
  anon: true
  userType: ChatUserType.VISITOR
  type?: never
  email?: never
  officialTitle?: never
}

export interface IBuyerVendorChatUserData extends IBaseChatUserData {
  anon?: never
  userType?: never
  type: ChatUserType
  email: string
  officialTitle?: string
}

export interface ILeadWithUnreadMessagesCount extends IBuyer {
  unreadMessagesCount?: number
}

export enum SupportHourStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  INVOICED = 'invoiced',
  PAID = 'paid',
  REFUNDED = 'refunded',
  CHARGE_FAILED = 'charge_failed',
  REFUND_FAILED = 'refund_failed',
}

export enum PlanPeriod {
  MONTHLY = 'monthly',
  BIMONTHLY = 'bimonthly',
  QUARTERLY = 'quarterly',
  HALF_YEARLY = 'half-yearly',
  YEARLY = 'yearly',
  BIENNIALLY = 'biennially',
}

export enum InternalSubscriptionStatus {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  NOT_SUBSCRIBED = 'not_subscribed',
  NOT_STARTED = 'not_started',
  COMPLETED = 'completed',
  RELEASED = 'released',
}

export enum InvoiceStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
}

export enum ChangelogAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  ACTIVATE = 'activate',
  IMPERSONATION_START = 'impersonation_start',
  IMPERSONATION_STOP = 'impersonation_stop',
}

export enum ProductServiceChangelogAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  PUBLISH = 'publish',
  TURN_INTO_DRAFT = 'turn_into_draft',
  UPDATED_AND_SAVED_AS_DRAFT = 'updated_and_saved_as_draft',
  UPDATED_AND_PUBLISHED = 'updated_and_published',
}

export enum SolutionChangelogAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  PUBLISH = 'publish',
  TURN_INTO_DRAFT = 'turn_into_draft',
  UPDATED_AND_SAVED_AS_DRAFT = 'updated_and_saved_as_draft',
  UPDATED_AND_PUBLISHED = 'updated_and_published',
  ADDED_PRODUCTS_SERVICES = 'added_products_services',
  REMOVED_PRODUCTS_SERVICES = 'removed_products_services',
}

export enum OpportunityStatus {
  INFO_SENT = 'info-sent',
  SALES_REP_ACTIVE = 'sales-rep-active',
  NURTURE = 'nurture',
  DISCOVERY_CALL = 'discovery-call',
  DEMO = 'demo',
  RFP = 'rfp',
  POC = 'poc',
  DEAD = 'dead',
  LOST = 'lost',
  WON = 'won',
  MATCH = 'match',
  NO_RESPONSE = 'no-response',
  OTHER = 'other',
}

export enum PaymentInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum CustomPlanName {
  ENTERPRISE = 'Enterprise',
}

export enum StandardPlanName {
  BETA = 'Beta',
  STARTER = 'Starter',
  PLUS = 'Plus',
  MAX = 'Max',
}

export enum Period {
  ALL = 'all',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum PeriodWithCustom {
  ALL = 'all',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export interface IWhitePaperAnalytics {
  whitePaperId: string
  whitePaperTitle: string
  whitePaperCategory: IMainCategory
  againstTotal: number
  count: number
}

export interface IWhitePaperAnalyticsExtras {
  total: number
}
export interface IVideoAnalytics {
  videoId: string
  videoTitle: string
  videoCategory: IMainCategory
  againstTotal: number
  count: number
}

export interface IVideoAnalyticsExtras {
  total: number
}

export interface IMetric {
  currentPeriodCount: number
  trendType: MetricType
  trendValue: number
}

export interface ISolutionsTrends {
  opportunities: IMetric
  leads: IMetric
  publishedSolutions: { total: number }
}

export interface IWhitePaperAnalyticsTrends {
  totalWhitePapers: number
  totalWhitePapersDownloadsByPeriod: IMetric
}

export interface IVideoAnalyticsTrends {
  totalVideos: number
  totalVideosViewsByPeriod: IMetric
}

export interface IMainCategoryAnalytics {
  [key: string]: number[]
}

export enum ArchivingAction {
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
}

export enum SupportHourPeriod {
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
}

export enum CustomUserAttribute {
  SIGNUP_GROUP = 'custom:signup_group',
}

export interface IFeatureAvailability {
  marketTrends: boolean
  buyerChatbot: boolean
  solutionV2: boolean
}

export enum PublicWhitePaperStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum PublicWhitePaperType {
  IT_CUSTOMER = 'it-customer',
  TECH_VENDOR = 'tech-vendor',
}

export interface IPublicWhitePaper {
  id: string
  slug?: string
  title: string
  type?: PublicWhitePaperType
  image: string | File | null | undefined
  paper: string | File | null | undefined
  paperFileName: string
  createdByAdmin: IAdmin
  status: PublicWhitePaperStatus
  description: string
  publishedDate: Date
  createdDate: Date
  updatedDate: Date
}

export enum PressPostStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export interface IPressPost {
  id: string
  title: string
  image: string | File | null | undefined
  externalUrl: string
  createdByAdmin: IAdmin
  status: PressPostStatus
  publishedDate: Date
  createdDate: Date
  updatedDate: Date
}

export enum BlogPostStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export interface IBlogPost {
  id: string
  slug?: string
  title: string
  type?: BlogPostType
  preview: string
  image: string | File | null | undefined
  createdByAdmin: IAdmin
  status: BlogPostStatus
  description: string
  descriptionDraft?: string | null
  descriptionTemplate?: IEntityContentJson
  descriptionTemplateDraft?: IEntityContentJson | null
  publishedDate: Date
  createdDate: Date
  updatedDate: Date
}

export interface IPublicBlogPost extends IBlogPost {
  neighborPostNavigationSlugs: {
    prevPostSlug: string
    nextPostSlug: string
  }
}

export interface IPublicWhitePaperWithNeighborSlugs extends IPublicWhitePaper {
  neighborPostNavigationSlugs: {
    prevPostSlug: string
    nextPostSlug: string
  }
}

export interface IEntityValidation {
  missingRequirements: string[]
}

export enum DistributorAlternativeFields {
  OTHER = 'Other',
  NONE = 'None',
}

export enum BlogPostType {
  IT_CUSTOMER = 'it-customer',
  TECH_VENDOR = 'tech-vendor',
}

export enum CalendarType {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
}

export enum CalendarCallType {
  DISCOVERY_CALL = 'discovery-call',
  DEMO = 'demo',
  RFP = 'rfp',
  POC = 'poc',
  OTHER = 'other',
}

export interface ICalendarEvent {
  id: string
  title: string
  description: string
  meetingLink?: string
  callType: CalendarCallType
  startDate: Date
  endDate: Date
  buyer: IBuyer
  vendor: IVendor
  solutionsV2?: ISolution_v2[]
  type: CalendarType
  createdDate: Date
  updatedDate: Date
}

export interface IAuthCalendar {
  type: CalendarType
}

export interface ITimeSlot {
  start: Date
  end: Date
}

export enum LeadSource {
  CONTACT_FORM = 'contact-form',
  IT_CUSTOMER_LEAD_MAGNET = 'it-customer-lead-magnet',
  TECH_VENDOR_LEAD_MAGNET = 'tech-vendor-lead-magnet',
  WHITEPAPER_LEAD_MAGNET = 'whitepaper-lead-magnet',
}

export enum ContactUserType {
  BUYER = 'buyer',
  VENDOR = 'vendor',
  OTHER = 'other',
}

export interface ILead {
  id: string
  firstName: string
  lastName: string
  source: LeadSource
  companyEmail: string
  companyName: string
  createdDate: Date
  phone: string
  userType: ContactUserType
  message: string
}

export interface IReferral {
  id: string
  offerId: number
  partnerId: number
  transactionId: string
  vendor: IVendor
  usedDate?: Date
  updatedDate: Date
  createdDate: Date
}

export type StreamChatEventNotificationNewMessage = Pick<
  Event,
  | 'channel'
  | 'channel_id'
  | 'channel_type'
  | 'cid'
  | 'created_at'
  | 'message'
  | 'received_at'
  | 'total_unread_count'
  | 'type'
  | 'unread_channels'
  | 'unread_count'
  | 'user'
  | 'watcher_count'
>

export type SolutionSearchGroupByEntity =
  | 'vendors'
  | 'mainCategories'
  | 'brands'
  | 'solutions'

export type ChatbotMessage = {
  role: ChatbotUser
  id?: string
  message: string
  reported?: boolean
  error?: boolean
  messageId?: string
}

export type QwilrPage = {
  id: string
  name: string
  status: string
  tags: string[]
  links: {
    pdfUrl: string
    editorUrl: string
    publicUrl: string
    collaboratorUrl: string
  }
  ownerId: string
}

export interface IVendorCampaign {
  id: string
  name: string
  iframe: string
  slug: string
  type: VendorCampaignType
  numberOfLeads?: number
  active: boolean
  vendor: Pick<IVendor, 'id' | 'firstName' | 'lastName'>
  createdDate?: Date
  updatedDate?: Date
}

export enum VendorCampaignType {
  MQL = 'mlq',
  MQL_PLUS = 'mlq_plus',
  BANT = 'bant',
}

export interface ISolution_v2 {
  id: string
  name: string
  description?: string
  logo?: string
  banner?: string
  slug?: string
  incomplete: boolean
  status: SolutionStatus_v2
  beforeMigrationStatus?: SolutionStatus
  changedAfterMigration: boolean
  useCompanyLogo?: boolean
  useCompanyBanner?: boolean
  freeTrialAvailability?: PricingAvailability
  freeVersionAvailability?: PricingAvailability
  pricingCustomizationOptions?: IPricingCustomizationOption_v2[]
  pricingTypes?: IPricingType_v2[]
  legacy: boolean
  type: SolutionType_v2
  detail?: string
  geos?: IGeo[]
  geoFocus?: GeoFocus_v2
  features?: ICategoryFeature_v2[]
  categories?: ICategory_v2[]
  opportunities?: IOpportunity[]
  setupOptions?: ISetupOptions_v2[]
  plansPackages?: ISolutionsPlanPackage_v2[]
  benefits?: ISolutionsBenefit_v2[]
  resources?: ISolutionResource_v2[]
  createdByVendor?: IVendor
  isMatchAccepted?: boolean
  hasLegacyMatch?: boolean
  isFavorite?: boolean
  createdDate?: Date
  updatedDate?: Date
  solution?: ISolution
}

export enum SolutionsPlanPackagePrice {
  FREE = 'free',
  CONTACT_US = 'contact-us',
  PRICE = 'price',
}

export interface IPlanPackageUnit_v2 {
  id: string
  name: string
  active: boolean
}

export interface IPlanPackageFrequency_v2 {
  id: string
  name: string
  active: boolean
}

export interface ISolutionsPlanPackage_v2 {
  id: string
  name: string
  description: string
  price: SolutionsPlanPackagePrice
  amount?: number
  order: number
  planPackageUnit: IPlanPackageUnit_v2
  planPackageFrequency: IPlanPackageFrequency_v2
}

export interface ISolutionsBenefit_v2 {
  id: string
  name: string
  description: string
  order: number
  icon: string
  iconColor: string
}

export interface ISetupOptions_v2 {
  id: string
  name: string
  active: boolean
  setupOptionFilter: ISetupOptionFilter_v2
  parentSetupOption?: ISetupOptions_v2
  children: ISetupOptions_v2[]
  solutions: ISolution_v2[]
  createdDate: Date
  updatedDate: Date
}

export interface ISetupOptionFilter_v2 {
  id: string
  name: string
  active: boolean
  setupOptions: ISetupOptions_v2[]
  createdDate: Date
  updatedDate: Date
}

export interface IPricingType_v2 {
  id: string
  name: string
  active: boolean
  parentPaymentType?: IPricingType_v2
}

export interface IPricingCustomizationOption_v2 {
  id: string
  name: string
  active: boolean
  createdDate: Date
  updatedDate: Date
}

export interface ISolutionResource_v2 {
  id: string
  name?: string
  description?: string
  highlighted?: boolean
  extension?: string
  thumbnail?: string
  url?: string
  fileName?: string
  order: number
  type: ResourceType
  createdDate: Date
  updatedDate: Date
}

export enum PricingAvailability {
  YES = 'yes',
  NO = 'no',
  NOT_APPLICABLE = 'not-applicable',
}

export enum SolutionStatus_v2 {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export enum SolutionType_v2 {
  PRODUCT = 'product',
  SERVICE = 'service',
}

export type EnterprisePlanSubscription = {
  cancelDate: Date
  nextInvoice?: {
    total: number
    dueDate: string
  }
  maxSolutions: number
  enterpriseCampaignsCount: number
  enterpriseCustomProductsCount: number
  paymentPastDue: boolean
}

export type EnterpriseInvoice = {
  id: string
  status: string
  pastDue: boolean
  issueDate: Date
  paidDate: Date | null
  dueDate: Date
  installment: number
  installments: number
  amount: number
  hostedInvoiceUrl?: string
}

export type EnterpriseBreakdown = {
  status: InternalSubscriptionStatus
  interval: PlanPeriod
  intervalCount: number
  numberOfInstallments: number
  installmentAmount: number
  totalAmount: number
  feePercentage: number
  createdDate: Date
  enterpriseCampaigns?: Pick<
    IEnterpriseCampaign,
    'id' | 'name' | 'type' | 'numberOfLeads'
  >[]
  enterpriseCustomProducts?: Pick<
    IEnterpriseCustomProduct,
    'id' | 'name' | 'description'
  >[]
  upcomingInvoice?: EnterpriseInvoice
  invoices: EnterpriseInvoice[]
}
